// Переопределение переменных bootstrap
@import '../../common/scss/theme-variables.scss';

// Базовые файлы bootstrap
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';
@import '~bootstrap/scss/_utilities.scss';

// Утилиты bootstrap
@import '~bootstrap/scss/utilities/display.scss';
@import '~bootstrap/scss/utilities/flex.scss';
@import '~bootstrap/scss/utilities/sizing.scss';
@import '~bootstrap/scss/utilities/position.scss';
@import '~bootstrap/scss/utilities/spacing.scss';
@import '~bootstrap/scss/utilities/text.scss';
@import '~bootstrap/scss/utilities/shadows.scss';
@import '~bootstrap/scss/utilities/screenreaders.scss';
@import '~bootstrap/scss/utilities/borders.scss';
@import '~bootstrap/scss/utilities/background.scss';

// Компоненты bootstrap
@import '~bootstrap/scss/alert.scss';
@import '~bootstrap/scss/dropdown.scss';
@import '~bootstrap/scss/reboot.scss';
@import '~bootstrap/scss/grid.scss';
@import '~bootstrap/scss/modal.scss';
@import '~bootstrap/scss/forms.scss';
@import '~bootstrap/scss/custom-forms.scss';
@import '~bootstrap/scss/buttons.scss';
@import '~bootstrap/scss/close.scss';
@import '~bootstrap/scss/type.scss';
@import '~bootstrap/scss/spinners.scss';
@import '~bootstrap/scss/card.scss';
@import '~bootstrap/scss/transitions.scss';
